/*
Template Name: QikTruck
Author: Themesdesign
File: Main scss file
*/

/*******************************
1.General
2.Helper
3.Navbar
4.Hero section
5.Testimonial
6.Pricing
7.Subscribe
8.Footer
9.Responsive
********************************/
:root {
  /* CSS variables for chat bubble styling */
  --user-msg-bg: #dcf8c6; /* Background for current user */
  --other-msg-bg: #f1f0f0; /* Background for other messages */
  --user-text-color: #000;
  --other-text-color: #000;
  --bubble-radius: 15px;
}
@import "variables";

/*******************
    1.General
*******************/

body {
  font-size: $font-size-base;
  color: $body-color;
  font-family: $font-family-primary;
  background-color: #f6f6f6;
}
* {
  scroll-margin-top: 90px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $dark;
  font-family: $font-family-secondary;
  font-weight: $font-weight-medium;
}
h5,
.h5 {
  font-size: 1.1rem;
}
a {
  text-decoration: none !important;
  outline: none;
}

p {
  line-height: 1.8;
  font-size: 15px;
}

/*******************
    2.Helper
*******************/

.card {
  border: none;
  box-shadow: $box-shadow;
  border-radius: 7px;
  margin-bottom: 30px;
}

.section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

.vertical-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.bg-overlay {
  background-color: rgba(35, 37, 47, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.small-title {
  letter-spacing: 1px;
  font-size: 14px;
}

.btn {
  font-size: 0.9rem;
  padding: 0.375rem 0.75rem !important;
  font-weight: $font-weight-medium;
  transition: all 0.4s;
  border-radius: 3px;
  &:hover {
    transform: translateY(-4px);
    outline: none;
    text-decoration: none;
  }
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.btn-rounded {
  border-radius: 30px;
}

@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: #{$value} !important;
  }

  .bg-soft-#{$name} {
    background-color: rgba(($value), 0.1) !important;
  }
  .text-#{$name} {
    color: #{$value} !important;
  }

  // Icons
  .icon-dual-#{$name} {
    color: $value;
    fill: rgba($value, 0.2);
  }

  .btn-#{$name} {
    color: $white;
    background: #{$value};
    border-color: #{$value};
    box-shadow: 0 8px 14px rgba($value, 0.2);

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      box-shadow: 0px 16px 31px -16px #{$value};

      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }

  .btn-outline-#{$name} {
    color: #{$value};
    border-color: #{$value};

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      box-shadow: 0px 16px 31px -16px #{$value};
      background: darken($value, 4%);
      border-color: darken($value, 4%);
    }
  }
}

.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 28px;
  padding-top: 15px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.icons-sm {
  height: 16px;
  width: 16px;
}

.icons-md {
  height: 32px;
  width: 32px;
}

.icons-lg {
  height: 40px;
  width: 40px;
}

.box-shadow {
  box-shadow: $box-shadow;
}

.avatar-md {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

/******************
    3.Navbar
*******************/

.navbar-custom {
  position: fixed;
  padding: 0px 0px;
  width: 100%;
  z-index: 999;
  margin-bottom: 0px;
  transition: all 0.5s ease-in-out;
  top: 0;
  background: white;
  .logo {
    margin-left: -10px;
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        line-height: 26px;
        color: rgba($dark, 0.8);
        transition: all 0.4s;
        background-color: transparent !important;
        padding: 6px 10px;
        margin: 0 7px;
        &:hover,
        &:active {
          color: $primary;
        }
      }
      &.active {
        .nav-link {
          color: $primary;
        }
      }
    }
  }

  .navbar-btn {
    margin-left: 10px;
    color: $success;
    background: rgba($success, 0.2);
    border-color: transparent;
    box-shadow: none;

    &:hover {
      color: $white;
      box-shadow: 0px 16px 31px -16px $success;
      background: darken($success, 4%);
      border-color: darken($success, 4%);
    }
  }
}
.logo {
  cursor: pointer;
}
.navbar-toggler {
  font-size: 24px;
  margin-top: 5px;
  margin-bottom: 0px;
  color: $white;
}

/*--- navbar Light ---*/

.navbar-light {
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: rgba($white, 0.5);
        &:hover,
        &:active {
          color: $white;
        }
      }
      &.active {
        .nav-link {
          color: $white;
        }
      }
    }
  }
  .logo {
    cursor: pointer;
    .logo-light {
      display: inline-block;
    }
    .logo-dark {
      display: none;
    }
  }

  .nav-sticky {
    .logo {
      cursor: pointer;

      .logo-dark {
        display: inline-block;
      }
      .logo-light {
        display: none;
      }
    }
  }
}

/*--- navbar sticky ---*/

.nav-sticky {
  &.navbar-custom {
    margin-top: 0px;
    padding: 0px;
    background-color: $white;
    box-shadow: 0 10px 33px -14px rgba(0, 0, 0, 0.7);
    .logo {
      .logo-light {
        display: none;
      }
      .logo-dark {
        display: inline-block;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: rgba($dark, 0.9);
          &:hover,
          &:active {
            color: $primary;
          }
        }
        &.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }
  }
}

/******************
    4.Hero section
*******************/

.hero-section {
  overflow: hidden;
}

.home-img {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 360px;
    height: 330px;
    background-color: rgba($primary, 0.1);
    border-radius: 30% 70% 59% 41% / 30% 38% 62% 70%;
    z-index: -1;
    top: 90px;
    right: 15px;
  }
}

/*--- Hero 2 ---*/

.hero-section-2 {
  padding: 230px 0px 170px 0px;
}

.registration-form {
  .form-control {
    box-shadow: none;
    &:focus {
      border-color: $primary;
    }
  }
}

/*--- Hero 5 ---*/

.hero-section-5 {
  padding: 300px 0px 220px 0px;
}

.play-icon-circle {
  height: 50px;
  width: 50px;
  background-color: $success;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.play-iconbar {
  color: $white;
  font-size: 17px;
}

/******************
    5.Testimonial
*******************/

.testi-carousel {
  .owl-nav {
    position: absolute;
    top: -67px;
    right: 0;
    margin-top: 0px;

    button {
      outline: 0;
      border-radius: 50% !important;
      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        background: rgba($primary, 0.2);
        color: $primary;
        border-radius: 50%;
        font-size: 20px;
      }

      &:hover {
        span {
          background: $primary;
          color: $white;
        }
      }
    }
  }

  .owl-item {
    padding: 0px 10px;
  }

  .owl-dots {
    .owl-dot {
      &:focus {
        outline: 0;
      }
      span {
        background: rgba($primary, 0.2);
        border: 2px solid transparent;
      }

      &.active,
      &:hover {
        span {
          background: $white;
          border: 2px solid $primary;
        }
      }
    }
  }
}

/********** Clients ***********/

.client-images {
  img {
    max-height: 94px;
    width: auto !important;
    margin: 10px auto;
    opacity: 0.6;
    transition: all 0.5s;
    &:hover {
      opacity: 0.9;
    }
  }
}

/******************
    6.Pricing
*******************/

.pricing-nav-tabs {
  display: inline-block;
  background-color: $white;
  box-shadow: $box-shadow;
  padding: 5px;
  border-radius: 7px;
  li {
    display: inline-block;
    .nav-link {
      border-radius: 7px;
      color: $body-color;
      padding: 0.6rem 1.4rem;
      &.active {
        background-color: $primary;
      }
    }
  }
}

.pricing-plan {
  max-width: 415px;
  margin: 0px auto;
  margin-top: 30px;
}

/******************
    7.Subscribe
*******************/

.subscribe {
  .form-control {
    height: 46px;
    border-radius: 7px;
    font-size: 15px;
    box-shadow: none;
    &:focus {
      border-color: $primary;
    }
  }
}
.form-control {
  border-width: 2px;
}
/******************
    8.Footer
*******************/
.row {
  margin-right: 0px;
  margin-left: 0px;
}
.footer {
  background-color: #4f6367;
  color: $white;
  padding-top: 40px;
  padding-bottom: 20px;
  margin-top: 30px;
  position: relative;
  a,
  p {
    text-align: center;
    color: white !important;
    width: 100%;
    float: left;
    img {
      margin-bottom: 10px;
    }
    &:hover {
      color: blue;
    }
  }
  h3 {
    color: #effdf3;
  }
}

.footer-list-title {
  font-size: 16px;
  color: $white;
}

.copyright-desc {
  width: inherit;
}
/******************
    9.Responsive
*******************/
.nav-get-quote {
  padding: 5px 15px;
  background-color: #4f6367;
  color: #d9ffe3;
  border-radius: 50px;
  width: max-content;
  text-decoration: none;
  font-size: 13px;
  position: relative;
}
a:hover {
  color: orange;
  text-decoration: none;
}
.menu-container {
  align-items: center;
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.footer-container {
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
}
.navbar-brand {
  padding-bottom: 0px;
  float: left;
}
@media (min-width: 991px) {
  .menu-container {
    flex-wrap: nowrap;
  }
}

@media (max-width: 991px) {
  .navbar-custom {
    margin-top: 0px;
    padding: 0px 0px 0px 0px !important;
    background-color: $light !important;
    box-shadow: 0 10px 33px -14px rgba(0, 0, 0, 0.1);

    &.navbar-expand-lg > .container {
      width: 95%;
    }

    .nav-get-quote {
      float: left;
    }
    .nav-get-quote:hover {
      color: #d9ffe3;
    }
    .logo {
      cursor: pointer;

      width: 100%;
      img {
        height: 25px;
      }
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          transition: all 0.4s;
          margin: 0px;
          padding: 6px 0px;
          color: rgba($dark, 0.9);
        }
        &.active {
          .nav-link {
            color: $primary;
          }
        }
      }
    }

    .navbar-btn {
      margin-left: 0px;
    }
  }
  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: rgba($dark, 0.9);
  }
}

@keyframes adLove {
  from {
    top: 0px;
  }

  33% {
    top: 50%;
  }

  66% {
    top: 0px;
  }
}
.selected-truck {
  background: #d9ffe3;
  border-radius: 50px;
  width: 100px;
  height: 100px;
  position: absolute;
  z-index: -1;
  animation-name: adLove;
  animation-duration: 0.5s;
  top: 15px;
  left: 35px;
}
.checked-truck {
  color: green;
  font-size: 20px;
  position: absolute;
  top: 10px;
  left: 20px;
}

.grey-border-bottom {
  border-bottom: solid 3px #817f7f4a;
}

.hide {
  display: none;
}

@media (max-width: 767.98px) {
  .hero-title {
    font-size: 20px;
  }
  .banner-vehicles {
    min-height: 40px;
  }
}

.banner-image {
  float: left;
  width: 48%;
  border-radius: 5px;
  margin-left: 1%;
  overflow: hidden;
  border: 4px solid #d7edd333;
}
.intro-image {
  float: left;
  width: 100%;
  border-radius: 5px;
  margin-left: 1%;
  overflow: hidden;
  border-radius: 5px;
}

@media (max-width: 575.98px) {
  .banner-image {
    width: 98%;
  }
  .testi-carousel {
    .owl-nav {
      display: none;
    }
  }
  .menu-truck {
    overflow: visible;
  }
}

label {
  color: #5b5b5b;
  font-weight: bold;
  font-size: 14px;
}

.divvy {
  border-left: 10px #4b4e4a33 dashed;
  height: 100px;
  width: 1px;
  z-index: -1;
  bottom: -90px;
}
.divvyup {
  border-left: 15px #71727033 dashed;
  height: 100px;
  width: 1px;
  bottom: 30px;
  z-index: -1;
}

.error-message {
  text-align: center;
  float: right;
  width: 100%;
  color: #e03c31;
  font-size: 13px;
}

.info-message {
  width: 100%;
  color: #e0a031;
  font-size: 13px;
}
.text-left {
  text-align: left;
}

.order-received {
  font-size: 18px;
  color: green;
  margin-right: 5px;
}
.order-received-list {
  padding-top: 5px;
  margin-right: 10px;
  font-size: 25px;
}
.black-text {
  color: #5b5b5b;
  font-size: 14px;
}

.grey-color {
  color: gray;
}
.red-color {
  color: #e03c31;
}
.green-color {
  color: orange;
}
.footer-social-logo {
  margin-right: 0px;
  float: left;
  width: fit-content !important;
  padding: 10px;
}
.footer-social-logo-img {
  height: 30px;
  float: left;
}
.footer-call-button {
  font-size: 22px;
  padding: 0px 2px;
  border: #fdd815 solid 2px;
  border-radius: 50px;
  width: auto !important;
}

.header-social-logo {
  margin-left: 15px;
}

.btn-primary {
  color: #4f6367;
  background: #fdd815;
  border-color: #fdd815;
  box-shadow: 0 8px 15px #bdbbb433;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #516265d9 !important;
  background-color: #c3c7cb;
  border-color: #99a3ad;
  opacity: 0.9;
  cursor: not-allowed;
}

.pac-container {
  background-color: #fff;
  z-index: 1050;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 20;
}

.modal-backdrop {
  z-index: 10;
}

.container {
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 90px;
}
.selected-button-truck {
  float: left;
  width: 180px;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 10px;
  color: orange;
  letter-spacing: 1px;
  text-decoration: underline;
  cursor: pointer;
  // .highlight {
  //   width: 30px;
  //   height: 30px;
  //   background: #4f6367;
  //   border-radius: 50px;
  //   position: absolute;
  //   z-index: -1;
  // }
  img {
    height: 50px;
    animation-name: adLove;
    animation-duration: 1s;
  }
}
.hire-button-truck {
  float: left;
  width: 140px;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 10px;
  color: #072f1f;
  cursor: pointer;
  // .highlight {
  //   width: 30px;
  //   height: 30px;
  //   background: #4f6367;
  //   border-radius: 50px;
  //   position: absolute;
  //   z-index: -1;
  // }
  img {
    height: 50px;
    animation-name: adLove;
    animation-duration: 1s;
  }
}
.white-color {
  color: #4f6367;
}
.price-listing {
  float: left;
  width: 100%;
  span {
    font-weight: bold;
  }
}

.receipt {
  .row {
    // border-top: solid 2px #000;
    // padding-top: 5px;
    // padding-bottom: 5px;
  }
}
html {
  scroll-behavior: smooth;
}
.usage-stats {
  text-align: center;
  float: left;
  width: 100%;
  margin-bottom: 30px;
  padding: 0px 20px 15px 0px;
  font-size: 23px;
  span {
    font-size: 13px;
  }
}

.driverguide {
  margin-bottom: 20px;
  ul {
    padding: 10px;
  }
  img {
    max-width: 100%;
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 5px;
    overflow: hidden;
    border: solid 2px #222223;
  }
}

.footer-quotation-button {
  span {
    color: #5b5b5b;
    background: #ffd814;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 14px;
  }
}

.footer-action-button {
  span {
    color: darkslategray;
    border-radius: 3px;
    background: #ffffff;
    padding: 5px 15px 5px 15px;
    font-size: 14px;
  }
}

.footer-business-button {
}
.footer-registration-button {
  span {
    color: darkslategray;
    border-radius: 50px;
    background: #ffffff;
    padding: 5px 15px 5px 15px;
    font-size: 14px;
  }
}
.footer-promote-button {
  span {
    color: #5b5b5b;
    background: white;
    border-radius: 50px;
    padding: 4px 12px;
    font-size: 14px;
  }
}
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 1px;
  padding-left: 1px;
}

.country-flag {
  max-width: 20px;
  margin-left: 10px;
}
.selectavehicle {
  width: 100%;
  margin-top: 10px;
}
.intextbutton {
  border-radius: 0;
  border: none;
  color: #4f6367;
  background: transparent;
  border-bottom: 1px solid;
}
.vehiclecategories {
  &.custom-sale {
    width: 100%;
    min-height: 230px;
  }
  &.truck-hire-sale {
    width: 100%;
    min-height: 200px;
  }
  border: 1px solid #4f6367;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  padding: 10px 15px;
  margin: 5px;
  margin-left: 0px;
  float: left;
  min-height: 140px;
  width: 31%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  background-color: #4f6367;
  cursor: pointer;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  font-size: 14px;
  overflow: hidden;
  .whiteblock {
    height: 100%;
    width: 110%;
    position: absolute;
    z-index: 0;
    left: -5%;
    top: 0px;
    border-radius: 0px;
    background: #4f6367;
    opacity: 0;
  }

  .categorytype {
    margin: 0;
    padding: 5px 10px;
    font-weight: bold;
    text-transform: capitalize;
    background: #ffffff;
    color: #5b5b5b;
    font-size: 21px;
    float: left;
    border-radius: 5px;
    margin-right: 35%;
  }
  &.selected {
    border-color: #ffd814;
    height: 60px;
  }
  span {
    font-size: 13px;
    font-weight: normal;
    text-transform: none;
    z-index: 1;
    position: relative;
    b {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .currentTruckType {
    position: absolute;
    right: 10px;
    min-width: 20px;
    min-height: 20px;
    text-align: center;
    border: 1px solid #4f6367;
    background: #ffffffcc;
    border-radius: 300px;
    font-weight: bold;
    box-shadow: 0px 0px 3px 2px #4f6367;
    &.mdi-check {
      background: #ffd814;
      color: #4f6367;
    }
  }
  .startingat {
    margin-top: 10px;
    background: #4f6367;
    padding: 5px 10px;
    float: left;
    color: #fff;
    border-radius: 3px;
    min-width: 40%;
    opacity: 0.9;
  }
  .selectone {
    font-weight: normal;
    text-transform: none;
    background: #9b8b6e;
    padding: 2px 10px;
    margin-top: 5px;
    float: left;
    border-radius: 3px;
  }
}

.landing-menu.row {
  margin-bottom: 20px;
}

@media (max-width: 575.98px) {
  .vehiclecategories {
    min-width: 100%;
  }
}

.furniture {
  background-image: url("https://media.qiktruck.co.za/banners/furniture.jpg");
}
.equipment {
  background-image: url("https://media.qiktruck.co.za/banners/equipment.jpg");
}
.materials {
  background-image: url("https://media.qiktruck.co.za/banners/materials-3.jpg");
}
.rubble {
  background-image: url("https://media.qiktruck.co.za/banners/rubble.jpg");
}
.vehicles {
  background-image: url("https://media.qiktruck.co.za/banners/vehicles.png");
}
.fresh-goods {
  background-image: url("https://media.qiktruck.co.za/banners/fresh-goods.jpg");
}
.chemicals {
  background-image: url("https://media.qiktruck.co.za/banners/chemicals.png");
}
.handheld-items {
  background-image: url("https://media.qiktruck.co.za/banners/handheld-items.jpg");
}
.category-collage {
  background-image: url("https://media.qiktruck.co.za/category-collage.png");
}

.logo-dark {
  height: 40px;
  margin: 10px;
}

.moreoptions {
  .form-group {
    float: left;
    text-align: left;
    padding: 15px 15px 10px 10px;
    cursor: pointer;
    color: #2c3b3e;
    width: 99%;
    text-decoration: underline;
    margin-bottom: 25px;
    background: #dee;
    border-radius: 5px;
  }
}
.modal-content {
  max-width: 500px;
  margin: 0 auto;
}
.nowprice {
  text-align: right;
}
.oldprice {
  word-spacing: 5px;
  padding: 0;
  margin: 0;
  color: #676464;
  line-height: 10px;
  margin-right: 20px;
  font-size: 12px;
  text-align: right;
  float: left;
  width: 100%;
}
.client-type {
  float: left;
  margin: 5px;
  padding: 25px;
  min-width: 85px;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 3px;
}

.clients-list {
  display: flow-root;
}
.new-menu {
  width: fit-content;
}
.custom-nav {
  right: 10px;
  top: 15px;
  position: absolute;
  line-height: 20px;
  padding-left: 5px;
  span {
    float: left;
    margin-top: 10px;
    font-size: 14px;
    width: fit-content;
    padding: 5px 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: grey;
  }
}
// @media (max-width: 390px) {
//   .custom-nav {
//     width: fit-content;
//     right: 0px;

//     a {
//       font-size: 13px;
//       margin-top: 0px;
//       margin-bottom: 0px;
//     }
//     span {
//       display: none;
//     }
//   }
// }
.customer-cart {
  text-align: right;
  float: right;
  font-size: 14px;
  margin-top: 30px;
  cursor: pointer;
  background: #4f6367;
  color: #fff;
  padding: 5px 10px;
  border-radius: 50px;
  text-decoration: none;
  span {
    font-weight: normal;
  }
}
.customer-cart :hover {
  color: #2ad357;
}

.sign-out {
  text-align: right;
  position: absolute;
  right: 20px;
  top: 25px;
  font-size: 10px;
}
.xsign-out {
  color: rgb(238, 210, 210);
  border: none !important;
  float: left !important;
}
.customer-login {
  float: right;
  font-size: 14px;
  cursor: pointer;
  color: #4f6367;
  padding: 5px 5px 0px 0px;
  border-radius: 2px;
  text-decoration: none;
  position: absolute;
  right: 10px;
  top: 10px;
  span {
    font-weight: normal;
    font-size: 17px;
  }
}
.customer-login :hover {
  color: orange;
}
div {
  &.nav-delivery-quotation {
    background-color: #008080;
  }
}
@media (max-width: 540px) {
  div {
    &.nav-delivery-quotation {
      position: absolute;
      top: -50px;
      right: 0;
    }
  }
}
.nav-delivery-quotation {
  &:hover {
    color: orange;
  }
  cursor: pointer;
  border: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-top: 10px;
  border-radius: 30px;
  width: fit-content;
  padding: 3px 10px;
  background-color: #4f6367;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 10px;
  display: initial;

  &.highlight {
    background-color: #f6c842;
    color: #1b2324;
    font-weight: normal;
    opacity: 0.8;
  }
}
.nav-truck-hire {
  float: left;
  border: none;
  margin-top: 10px;
  font-size: 14px;
  border-radius: 3px;
  padding: 5px 15px;
  background: #4f6367;
  color: #fff;
  margin-bottom: 10px;
  width: fit-content;
}

.nav-options {
  display: inline;
}
.news-article {
  padding: 10px;
  margin: 5px;
  display: block;
  h1 {
    font-size: 20px;
  }
  p {
    margin-left: 10px;
    font-size: 13px;
    color: #5b5b5b;
  }
  i {
    color: #00a6ff;
    padding: 5px;
  }
  a {
    color: #4f6367;
  }
  .article-list-image {
    padding: 30%;
    margin-bottom: 10px;
    border-radius: 5px;
    opacity: 0.9;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.landing-menu {
  p {
    margin: 0%;
    color: grey;
    font-size: 10px;
    margin-top: -10px;
    width: 100%;
  }
}
.banner-vehicles-fader {
  background-image: url(https://media.qiktruck.co.za/qiktruck-activity-map.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  float: left;
  width: 100%;
  overflow: hidden;
  min-height: 400px;
  border-radius: 50px;
  padding: 20px;
  opacity: 0.7;
  .banner-vehicle {
    float: left;
    img {
      height: 30px;
      opacity: 0.9;
    }
  }
}
.banner-vehicles {
  float: left;
  width: 100%;
  overflow: hidden;
  min-height: 40px;
  .banner-vehicle {
    float: left;
    img {
      height: 30px;
    }
  }
}

@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: " ";
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.home-news {
  text-align: left;
  padding: 20px;
  border: solid 1px #efefef;
  display: block;
  margin: 5px;
  border-radius: 5px;
  max-height: 170px;
  overflow: hidden;
  margin-top: 20px;
  background-color: #ffffff;
  h1 {
    font-size: 13px;
  }
  p {
    font-size: 12px;
    color: gray;
    max-width: 250px;
  }
  .date-article-added {
    font-size: 10px;
  }

  .article-list-image {
    padding: 10%;
    margin-bottom: 10px;
    border-radius: 5px;
    opacity: 0.9;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 50%;
  }
}
.home-reviews {
  text-align: left;
  padding: 20px;
  border: solid 1px #efefef;
  display: block;
  margin: 5px;
  border-radius: 5px;
  max-height: 170px;
  overflow: hidden;
  margin-top: 20px;
  background-color: #ffffff;
  h1 {
    font-size: 13px;
  }
  p {
    font-size: 12px;
    color: gray;
    max-width: 250px;
  }
  .date-article-added {
    font-size: 10px;
  }
  .mdi-star {
    color: orange;
    margin-left: 10px;
    font-size: 13px;
  }
}
.store-front {
  // background: #4f63675e;
  border-radius: 50px;
  float: left;
  margin-bottom: 40px;
  margin-left: -10%;
  padding-left: 10%;
  display: table-row;
  padding-right: 4%;
  width: 113%;
  .form-control {
    background-color: #ffffffef;
  }
  h3 {
    color: #2c3b3e;
  }
  label {
    color: #2c3b3e;
  }
}
// .store-front {
//   width: 113%;
//   float: left;
//   margin-bottom: 40px;
//   background: #ccc;
//   margin-left: -10%;
//   padding-left: 10%;
//   padding-right: 3%;
// }

.vehicle-type {
  cursor: pointer;
  border: 1px solid #aec4a8;
  margin: 5px;
  padding: 10px 10px 20px 10px;
  border-radius: 5px;
  display: inline-block;
  background: white;
  &:hover {
    border: solid 1px #82dd6d;
  }
  .close {
    color: rgb(3, 3, 3);
  }
}
.regular-vehicle-type {
  cursor: pointer;
  border: 1px solid #aec4a8;
  margin: 5px;
  padding: 10px 10px 20px 10px;
  border-radius: 5px;
  display: inline-block;
  background: white;
  &:hover {
    border: solid 1px #82dd6d;
  }
  .close {
    color: rgb(3, 3, 3);
  }
}
@media (min-width: 992px) {
  .vehicle-type {
    &.col-lg-4 {
      flex: 0 0 33.3333333333%;
      max-width: 30%;
      cursor: pointer;
      border: 1px solid #aec4a8;
      margin: 5px;
      padding: 10px 10px 20px 10px;
      border-radius: 5px;
      display: inline-block;
      background: white;
      &:hover {
        border: solid 1px #82dd6d;
      }
    }
  }
  .regular-vehicle-type {
    &.col-lg-4 {
      flex: 0 0 33.3333333333%;
      max-width: 30%;
      cursor: pointer;
      border: 1px solid #aec4a8;
      margin: 5px;
      padding: 10px 10px 20px 10px;
      border-radius: 5px;
      display: inline-block;
      background: white;
      &:hover {
        border: solid 1px #82dd6d;
      }
    }
  }
}
.price-tag-from {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 12px;
}
.price-tag {
  width: auto !important;
  position: absolute !important;
  top: 20px !important;
  bottom: auto !important;
  align-self: auto !important;
  right: 10px !important;
  font-size: 23px !important;
  letter-spacing: 2px !important;
}
.price-tag-promo {
  position: absolute;
  top: 50px;
  right: 20px;
  font-style: italic;
  font-size: 13px;
  color: #808080b0;
  text-decoration: line-through;
  font-weight: normal;
  letter-spacing: 2px;
}
.view-more-menu {
  position: absolute;
  top: 84px;
  right: 10px;
  font-size: 13px;
}

.loadgroup {
  background-color: #e9ede7;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 2px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  left: 5px;
}
.menu-truck {
  height: 100%;
  max-height: 60px;
  margin-right: 5px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 20px;
}
.menu-mini-truck {
  height: 100%;
  max-height: 40px;
  margin-right: 15px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}
.menu-mini-truck-left {
  height: 80%;
  max-height: 40px;
  margin-right: 0px;
  margin-top: 10px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  float: left;
}
.menu-truck-type {
  font-size: 14px !important;
  float: left;
  width: 100%;
  text-align: center;
  cursor: pointer;
  b {
    font-size: 14px;
    text-decoration: underline;
    color: #4f6367;
  }
}
.menu-truck-tip {
  font-size: 14px !important;
  float: left;
  width: 100%;
  text-align: left;
  cursor: pointer;
  margin-bottom: 0;
  b {
    font-size: 14px;
    text-decoration: underline;
    color: #4f6367;
  }
}

.select-body-prompt {
  font-size: 13px !important;
  color: orange !important;
  text-align: left !important;
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  padding-left: 0;
  margin-top: 10px;
}
.body-options {
  position: relative;
  float: left;
  width: 100%;
  display: block;
  .option-button {
    width: 100%;
    float: left;
    display: inline-grid;
    border-radius: 4px;
    color: #ffdf7fc7;
    padding: 5px 10px;
    border: 1px solid #ddd;
    margin-bottom: 5px;
    font-size: 15px;
    text-transform: capitalize;
    background: none;
    position: relative;
    .quote-bodylabel {
      color: #7d8b8e;
      font-size: 10px;
      position: absolute;
      right: 10px;
      bottom: 5px;
    }
    .price-tag {
      color: #555;
      width: auto !important;
      position: absolute !important;
      top: 3px !important;
      bottom: auto !important;
      align-self: auto !important;
      right: 10px !important;
      font-size: 18px !important;
      letter-spacing: 0.5px !important;
    }
  }
  .open-chat-button {
    display: none;
    background-color: #4caf50;
    color: white;
    padding: 0px 5px;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    margin-bottom: 20px;
    &:hover {
      background-color: #4caf50;
    }
    position: absolute;
    right: 0;
    top: -5px;
  }
}
.driver-included {
  color: green;
}
.category-hastags {
  float: left;
  margin-top: 20px;
  display: block;
  font-size: 10px;
  color: #4f6367;
}
.menu-truck-selected {
  padding: 0px;
}

// .store-front {
//   background: #4f6367;
//   padding: 15px;
//   border-radius: 50px;
//   color: white !important;
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     color: white;
//   }
// }

.looking-for-driver {
  background-color: #e9d158;
  border-radius: 5px;
  padding: 5px 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  color: #4f6367;
  width: fit-content;
  float: left;
  font-size: 16px;
  text-align: left;
}
.refresh-screen {
  background-color: #4f6367;
  padding: 5px;
  color: #d9ffe3;
  text-align: center;
  font-size: 35px;
  cursor: pointer;
}
.partner-logos {
  img {
    height: 50px;
    padding: 5px;
    float: left;
  }
}

.orders-table {
  padding-left: 10px;
  padding-bottom: 120px;
  padding-right: 10px;
  padding-top: 120px;
}
.orders-table {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 120px;
}
.order-row {
  padding: 5px 10px 5px 10px;
  margin: 5px 15px 5px 15px;
  border-bottom: 2px solid #4f6367;
  display: flow-root;
  color: #4f6367;
  div {
    margin-right: 5px;
  }
}
.order-vehicle {
  float: left;
}
.order-distance {
  float: left;
}
.order-date {
  float: left;
  width: 100%;
  font-size: 16px;
}
.order-time {
  float: left;
}
.order-details {
  float: left;
  width: 100%;
}
.order-price {
  float: right;
  font-size: 18;
  font-weight: bold;
}
.order-status {
  margin-top: 10px;
  margin-bottom: 10px;
  align-self: flex-end;
  font-weight: bold;
}

.customer-profile {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 60px;
  padding-bottom: 120px;
}

.vehicle-profile {
  float: left;
  margin-right: 10px;
  font-size: 18px;
  color: #4f6367;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
}

.vehicle-profile-avatar,
.vehicle-profile-data {
  float: left;
  margin-right: 10px;
  font-size: 18px;
  color: #4f6367;
}

.vehicle-profile-images {
  float: left;
  width: 100%;
  margin-top: 10px;
  img {
    margin-bottom: 10px;
    margin-right: 10px;
    border: double 3px transparent;
    border-radius: 80px;
    width: 90px;
    height: 90px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, rgb(0, 140, 255), #d9ffe3, orange);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
}
.describe-advert {
  color: #4f6367;
  font-size: 13px;
}

.vehicle-profile {
  margin-bottom: 0px;
  width: 100%;
  a {
    color: #4f6367;
  }
  p {
    float: left;
    font-size: 13px;
    color: #4f6367;
  }
}

.activity-map {
  width: 100%;
  display: inline-block;
  img {
    border-radius: 5px;
    width: 340px;
    float: left;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-left: 0px;
    box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  }

  ul {
    float: left;
    padding-left: 15px;
    font-size: 16px;
    li {
      line-height: 30px;
    }
  }
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #c6cbc1;
  background-color: #c6cbc1;
  border-color: #ccc;
}

.btn-primary:hover {
  color: #4f6367;
  border-color: #fdd815;
  box-shadow: 0px 16px 31px -16px #c6cbc1;
  background: #fdd815;
}

.footer {
  h3 {
    span {
      margin-right: 10px;
    }
  }
}

.ft-cat-house {
  color: lightblue;
}
.ft-cat-office {
  color: #d9ffe3;
}
.ft-cat-construction {
  color: lightblue;
}
.ft-cat-online {
  color: #d9ffe3;
}

.map-benefits {
  li {
    font-size: 18px;
    margin-bottom: 15px;
  }
}
.truck-hire,
.move-anything,
.move-goods {
  background: #fff;
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  padding: 10px;
  margin-top: 34px;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 5px 6px 0 #ddd, 0 0 4px 0 #ddd;
  }
}
@media (min-width: 768px) {
  .truck-hire,
  .move-anything,
  .move-goods {
    flex: 0 0 30%;
    max-width: 30%;
    margin-right: 1%;
  }
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 48%;
  }
}
.move-anything {
  a {
    color: #4f6367;
  }
  p {
    margin-left: 10px;
  }
  h3 {
    padding: 4px 20px 4px 5px;
    border-radius: 3px;
    font-size: 20px;
    width: fit-content;
    margin-left: 0;
  }
}
.truck-hire {
  .mdi-star {
    color: orange;
    margin-right: 10px;
  }
  a {
    color: #4f6367;
  }
  p {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  h3 {
    padding: 4px 20px 4px 5px;
    border-radius: 3px;
    font-size: 20px;
    width: fit-content;
  }
}
.move-goods {
  a {
    color: #4f6367;
  }
  p {
    margin-left: 10px;
    margin-bottom: 5px;
  }
  h3 {
    padding: 4px 20px 4px 5px;
    border-radius: 3px;
    font-size: 20px;
    width: fit-content;
  }
}

.other-services {
  &:hover {
    border: none;
  }
  a {
    color: #4f6367;
    text-transform: capitalize;
    padding-left: 20px;
  }
  p {
    margin-left: 10px;
  }
  h3 {
    padding: 4px 20px;
    border-radius: 3px;
    font-size: 20px;
    width: fit-content;
  }
}

.category-below {
  background-color: #e9e7de;
  padding: 5px 25px 5px 15px;
  border-radius: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: fit-content;
  font-size: 20px;
}
.driver-below {
  background-color: #c1f2eb;
  padding: 5px 25px 5px 15px;
  border-radius: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: fit-content;
  font-size: 20px;
}
.main-headline {
  font-size: 40px;
  margin-bottom: 20px;
}
.sub-heading {
  background-color: #2196f3;
  padding: 10px 20px;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
  width: -moz-fit-content;
  color: aliceblue;
  width: fit-content;
  margin-left: 20px;
}
.whats-new {
  margin-left: 5px;
  color: #00a6ff;
  border-radius: 50px;
  padding: 2px 6px;
  font-size: 10px;
  background-color: #d9ffe3;
  &.promo {
    background-color: #f8efac;
  }
}
.pkgs {
  span {
    border: 1px solid #efefef;
    background-color: #ffff00;
    padding: 10px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
}
.driver-packages {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0px;
  margin-bottom: 30px;
  border: 1px solid #efefef;
  border-radius: 8px;
  img {
    margin-top: 10px;
  }
  .img-holder {
    min-height: 80px;
  }
  h3 {
    border-radius: 50px;
    padding: 5px 20px;
    width: fit-content;
    margin-bottom: 10px;
    .span {
      font-size: 13px;
      color: rgba(236, 171, 58, 0.966);
      margin-left: 10px;
    }
    span {
      font-size: 18px;
    }
  }
  h4 {
    position: relative;
    background-color: #efefef;
    border-radius: 50px;
    padding: 5px 40px;
    margin-bottom: 10px;
    span {
      font-size: 13px;
    }
    i {
      font-size: 12px;
      text-align: left;
      top: 13px;
      position: absolute;
      right: 20px;
    }
  }
  p {
    padding-left: 20px;
  }

  &.EasyDrive {
    border-right: solid 1px #efefef;
    padding-right: 10px;
    h4,
    h3 {
      background-color: #d9ffe3;
      color: #4f6367;
    }
  }
  &.QikDrive {
    border-right: solid 1px #efefef;
    padding-left: 30px;
    padding-right: 10px;
    h3 {
      background-color: #cfd4d5;
      color: #1a201c;
      padding: 3px 15px;
      font-size: 14px;
    }
    h4 {
      background-color: #491e3c;
      color: #d9ffe3;
      width: fit-content;
    }
  }
  &.PremiumDrive {
    padding-left: 30px;
    h3,
    h4 {
      background-color: #491e3c;
      color: #d9ffe3;
    }
  }
}

@media (max-width: 991px) {
  .driver-packages {
    padding-left: 0px !important;
  }
}

.EasyDrivePackage {
  background-color: #d9ffe3;
  color: #4f6367;
  border-radius: 50px;
  padding: 5px 20px;
  width: fit-content;
  margin-bottom: 10px;
}

.footprint-info {
  display: inline-block;
  justify-content: space-between;
  display: flex;
  a {
    float: left;
    img {
      max-width: 320px;
    }
  }
}
@media (max-width: 500px) {
  .footprint-info {
    display: inline-block;
  }
}

.fullquote {
  float: right;
  width: 100%;
  padding: 5px;
  color: #4f6367;
  text-align: right;
  font-size: 35px;
  // margin-top: 100px;
  display: block;
}
#fullquote {
  width: 100%;
  padding: 5px;
  color: #4f6367;
  text-align: right;
  padding-top: 100px;
  display: block;
}
.fullquote-days {
  float: left;
  font-size: 13px;
  text-align: center;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 40px;
  font-weight: normal;
}
.sellingPriceValue {
  float: right;
}
.sell-nextbutton {
  padding: 5px 30px;
  line-height: 20px;
  font-size: 14px;
  border: none;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(244, 173, 41);
  box-shadow: 0 3px 3px 0 #ddd, 0 0 2px 0 #ddd;
  color: #42482d;
  outline: none;
  float: right;
  width: 100%;
  cursor: pointer;
  &:disabled {
    background-color: #c2bcbc;
    color: #6c7274;
    cursor: not-allowed;
  }
}
.sellingPriceThree {
  padding: 0px 0px;
  &.active {
    border: solid 3px #85e89f;
    margin-bottom: 20px;
    font-weight: bold;
    border-radius: 5px;
    display: table;
  }
}
.hireprice {
  padding: 10px 20px;
  font-size: 40px;
  font-weight: bold;
  .dayx {
    float: left;
    width: 100%;
    font-size: 13px;
    font-weight: normal;

    &.bad {
      color: red;
      font-weight: bol;
    }
  }
}
.sellingPriceTwo {
  padding: 0px 0px;
  &.active {
    border: solid 3px #85e89f;
    margin-bottom: 20px;
    font-weight: bold;
    border-radius: 5px;
    display: table;

    button {
      padding: 7px 45px;
      line-height: 20px;
      font-size: 14px;
      border: none;
      border-radius: 30px;
      margin: 0;
      background-color: orange;
      box-shadow: none;
      color: #4f6300;
      outline: none;
    }
  }
}

.sellingPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  &.active {
    margin-bottom: 20px;
    font-weight: bold;
    border-radius: 5px;
    display: table;
  }
}

.price-heading {
  font-size: 26px;
  text-align: right;
  color: orange;
  font-weight: bold;
  display: none;
}
.footer-sections {
  a {
    float: none;
    display: contents;
  }
}

.get-a-quotation-now {
  display: flex;
  padding: 5px;
  position: relative;
  min-height: 90px;
  justify-content: center;
  align-items: center;
  a {
    position: relative;
    border: 6px solid #d7edd333;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 18px;
    margin: 0 auto;
    font-weight: bold;
    display: block;
    color: #dfba6f !important;
    width: 100%;
  }
}

.find-transporters {
  cursor: pointer;
  input {
    border-radius: 100px;
  }
  .sell-truck-type {
    float: left;
    margin: 5px;
    margin-top: 10px;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 2px 10px;
    &.selected {
      background-color: #4caf50;
      color: #d9ffe3;
    }
    &.reset {
      border: solid 1px rgb(237, 180, 142);
      font-weight: bold;
      color: #cdc0c0;
    }
  }
}
.search-adverts {
  width: 100%;
  border-radius: 100px;
  max-width: 280px;
  box-shadow: 2px 5px 20px 2px #ccc;
}

.search-results {
  min-height: 300px;
  h4 {
    span {
      margin: 10px;
      font-size: 12px;
      font-weight: normal;
      background-color: #d9ffe3;
      border-radius: 5px;
      padding: 3px 10px;
    }
  }
}

.ad-vehicle {
  &.selected {
    border: solid 1px green;
    border-radius: 5px;
  }
}
.ad-preview {
  position: relative;
  padding: 5px;
  border: solid 1px #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  display: table;
  width: 100%;
  .time {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 12px;
  }
  .getauote {
    position: absolute;
    right: 10px;
    bottom: 5px;
    font-size: 12px;
    font-weight: normal;
    background-color: #fdd81773;
    border-radius: 25px;
    padding: 3px 10px;
    color: black;
  }
  .distance {
    position: absolute;
    left: 10px;
    bottom: 5px;
    font-size: 12px;
    font-weight: normal;
    background-color: #4f6367;
    border-radius: 25px;
    padding: 3px 10px;
    color: #d9f4ff;
    &.notset {
      background-color: #c58d63;
    }
  }
  .ad-info {
    float: left;
    p {
      color: #4f6367;
      font-size: 12px;
    }
  }
  .ad-image {
    min-width: 40%;
    max-width: 200px;
    max-height: 150px;
    float: left;
    margin-right: 10px;
    border-radius: 5px;
    overflow: hidden;
    background-image: url("https://media.qiktruck.co.za/qiktruck-preview-header.png");
    background-repeat: no-repeat;
    background-size: cover;
    img {
      display: block;
      width: 100%;
    }
  }
  .ad-link {
    position: relative;
    display: inline-flex;
    width: 100%;
  }
}
.status {
  margin: 10px;
  font-size: 12px;
  font-weight: normal;
  background-color: #d9ffe3;
  border-radius: 5px;
  padding: 3px 10px;
  &.paused {
    background-color: #ffe3d9;
  }
  &.disabled {
    background-color: #c85e37;
  }
  &.processing {
    background-color: #d9f4ff;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.newsletter-form {
  h4,
  p {
    color: white !important;
  }
  .nav-get-quote {
    background-color: burlywood;
    width: fit-content;
    align-self: center;
    color: #4f6367 !important;
  }
}
.stories {
  div {
    justify-content: center;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #99e4ad;
  background-color: #4f6367;
  border-color: #4f6367;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #4f6367;
}

.more-articles {
  ul {
    padding: 0;
  }
  li {
    float: left;
    width: 320px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    list-style: none;
    border: solid 1px #ccc;
    font-size: 14px;
    a {
      float: left;
      width: 100%;
      padding: 3px;
    }
  }
  span {
    width: 100px;
    margin-right: 15px;
    float: left;
  }
  .more-articles-img {
    float: left;
    margin: 5px;
    border-radius: 5px;
    width: 100%;
    min-height: 100px;
  }
}

.price-survey-field {
  width: 150px;
  float: right;
}
.price-survey-label {
  float: left;
  margin-right: 20px;
  min-width: 50%;
}

.price-survey-row {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid #efefef;
}
.price-survey-currency {
  float: right;
  font-weight: bold;
  font-size: 14px;
  margin-right: 3px;
  align-self: flex-start;
}
.price-survey-tip {
  float: right;
  font-size: 12px;
  margin-left: 3px;
  align-self: flex-start;
  color: orange;
  width: 55%;
  text-align: right;
  margin-right: 5px;
}
.price-survey-hint {
  float: left;
  font-size: 12px;
  margin-left: 3px;
  align-self: flex-start;
  color: #007bff;
  width: 55%;
  text-align: left;
  margin-right: 5px;
}

.orange {
  color: #dd940e;
  margin-right: 10px;
}

.orange2 {
  color: #4e5154;
  margin-right: 10px;
  margin-left: 10px;
  font-style: normal;
}

@media (max-width: 768px) {
  .orange2 {
    width: 100%;
    display: block;
    margin-top: 20px;
  }
}

.green {
  color: #6fdd0e;
  margin-right: 10px;
}

.leader-board-member {
  float: left;
  width: 100%;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 3px;
  img {
    border-radius: 100px;
    border: solid 1px #ccc;
  }
  &.winners {
    border: solid 3px rgba(13, 137, 27, 0.362);
    margin-bottom: 10px;
    margin-top: 10px;
    background-color: #e2f6e37d;
    .leader-board-member-count {
      font-size: 16px;
    }
  }
  .leader-board-member-count {
    font-weight: bold;
    font-size: 12px;
  }
}

.social-content-preview {
  min-height: 0.28vw;
  width: 100%;
  float: left;
  border: solid 1px #ccc;
  padding: 3px;
}
.social-content-parent {
  .social-content-preview-downloader {
    font-size: 32px;
    float: left;
  }
}

.leader-board-member-name {
  font-size: 14px;
  font-weight: 700;
  text-transform: capitalize;
}
.leader-board-member-link {
  text-decoration: none;
  color: #4f6367;
  text-transform: lowercase;
  text-align: right;
  width: 100%;
  float: left;
}
.leader-board-member-submission {
  button,
  input {
    text-decoration: none;
    color: #4f6367;
    text-transform: lowercase;
    text-align: right;
    width: 100%;
    max-width: 400px;
    margin-right: 20px;
    float: left;
    border-radius: 5px;
    border: solid 2px #4f6367;
    margin-bottom: 10px;
    color: #c58d63;
    transition-duration: 4s;
    padding: 5px 10px;
  }
  button {
    width: fit-content;
    float: none;
  }
  p {
    color: #c58d63;
  }

  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer;
    background: #2a9a2a;
    border: none;
    color: wheat;
    transition-duration: 4s;
  }
}

.accept-terms {
  padding: 5px 20px;
}

.modal-dialog {
  max-width: 99%;
}
.profile-editor {
  padding: 0px 10px;
  margin-bottom: 20px;
  border-left: 1px solid #ccc;
}

.open-profile {
  border-radius: 3px;
  border: 2px solid #ccc;
  color: #444;
  font-size: 12px;
  margin-right: 20px;
}

.customer-dash-option {
  padding: 10px 20px;
  margin: 20px;
  border-radius: 5px;
  cursor: pointer;
  a {
    color: #4f6367;
    cursor: pointer;
  }
}
.hire-vehicles {
  background-color: #008080;
  color: #ceead1 !important;
  a {
    color: #ceead1 !important;
  }
  h4 {
    color: wheat;
  }
}

.book-onceoff {
  background-color: #4c656a;
  color: #ceead1 !important;
  a {
    color: #ceead1 !important;
  }
  h4 {
    color: wheat;
  }
}
.list-contracts {
  background-color: #637c81;
  color: #ceead1 !important;
  a {
    color: #ceead1 !important;
  }
  h4 {
    color: wheat;
  }
}
.all-trips {
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
}

.menu-truck-tonnes {
  float: left;
  width: 100%;
  font-weight: bold;
}

.google-map {
  width: 90%;
  border: solid 5px #4f6367ac;
  border-radius: 5px;
  margin: 20px;
}
.contract-vehicle-list-image {
  float: left;
  width: 90px;
  margin-right: 30px;
  margin-top: 10px;
  display: block;
}

.contract-summary {
  float: left;
  line-height: 20px;
  button {
    margin-right: 10px;
    background-color: #efefef;
    border: solid 2px #ccc;
    border-radius: 3px;
  }
}

.contract-summary-date {
  float: left;
  font-size: 12px;
  margin-bottom: 5px;
}

.contract-summary-price {
  float: left;
  width: 100%;
}
.contract-summary-description {
  float: left;
  width: 100%;
}

.contract-summary-status {
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 3px;
  background-color: #e1bfbf;
  font-size: 13px;
  &.processing {
    background-color: #999ec9;
    color: white;
  }
  &.approved {
    background-color: #bfe1c0;
  }
  &.completed {
    background-color: #a3e7a5;
    color: white;
  }
}
.contract-summary-id {
  float: left;
  width: fit-content;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 3px;
  color: wheat;
  background-color: #77868a;
  font-size: 13px;
}
.contract-summary-button {
  margin-top: 10px;
  float: left;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0px 10px;
  border-radius: 3px;
  border: solid 2px #ccc;
  width: fit-content;
  font-size: 14px;
  color: #2c3b3e;
  background-color: #f5f5f5;
}
.hires-prices {
  span {
    float: left;
    width: 30%;
  }
  border-bottom: 2px solid #ccc;
}

.hire-price-value {
  border-bottom: 1px solid #ccc;
  margin-right: 10px;
}

.contract-vehicles-list {
  float: left;
  width: 100%;
  padding-left: 0px;
  border-left: 3px solid #ccc;
  margin-bottom: 5px;
  img {
    width: 40px;
  }
  &.defaultvehicle {
    a {
      color: #4caf50;
    }
  }
}
.menu-vehicles-list {
  width: 100%;
  display: flow-root;
}

.menu-vehicle {
  cursor: pointer;
  border: 1px solid #aec4a8;
  margin: 5px;
  padding: 10px 10px 20px;
  border-radius: 5px;
  display: inline-block;
  background: #fff;
  width: 320px;
  margin-right: 10px;
  min-height: 150px;
  img {
    width: 40px;
  }
  &.defaultvehicle {
    a {
      color: #144c2a;
    }
  }
  .vehicle-names {
    font-size: 14px;
    span {
      color: gray;
      margin-right: 10px;
      font-size: 12px;
      position: absolute;
    }
    p {
      position: relative;
      span {
        color: rgb(249, 247, 247);
        margin-right: 10px;
        font-size: 12px;
        position: absolute;
        bottom: -30px;
        right: 0;
        background-color: #008080;
        padding: 0px 15px;
        border-radius: 3px;
      }
    }
  }
}
.contract-trips-list {
  float: left;
  width: 100%;
  padding-left: 15px;
  padding-top: 5px;
  color: #222223;
  .contract-trips-date {
    font-size: 12px;
    color: #888;
  }
  a {
    color: #222223;
  }
  img {
    width: 50px;
  }
}

.vehicle-names {
  font-size: 14px;
  span {
    color: gray;
    margin-right: 10px;
    font-size: 12px;
  }
}
.hire-price-rate {
  float: left;
  width: 100%;
  font-size: 10px;
  display: contents;
}
.vehicle-type-rate {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #ccc;
  span {
    font-weight: bold;
    padding: 3px;
    margin-top: 5px;
    width: fit-content;
    font-size: 15px;
  }
}
.contract-trip-booker {
  border-left: 1px solid #ccc;
  padding-left: 15px;
  padding-top: 15px;
  margin-left: 15px;
}

.edit-trip {
  border: solid 1px #ccc;
  width: fit-content;
  float: right;
  padding: 3px 20px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #e1d2bf;
}
.cancel-trip {
  border: solid 1px #ccc;
  width: fit-content;
  float: right;
  padding: 3px 20px;
  font-size: 13px;
  color: #777;
  border-radius: 3px;
  background-color: #d7d4d4;
  margin-left: 10px;
}

.contract-trip-track {
  label {
    margin-top: 20px;
  }
}

.login-form {
  width: 100%;
  max-width: 320px;
  float: left;
}

.back-btn {
  padding: 0px 10px;
  font-size: 17px;
  background-color: #c4d3a5;
  border-radius: 3px;
  margin-bottom: 10px;
  display: block;
  color: #072f1f;
  width: fit-content;
}

.orange {
  color: #dd940e;
}

.contract-menu-icon {
  font-size: 55px;
}
label {
  span {
    color: red;
  }
}

.borderleft {
  border-left: thick solid grey;
  padding-left: 10px;
}

.vehicle-hired-list {
  margin-top: 5px;
  margin-bottom: 60px;
}

.trip-status {
  background-color: #d9d3d3;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;

  &.in-review {
    background-color: #eae6ce;
  }
  &.stopped {
    background-color: #ead0ce;
  }
  &.accepted {
    background-color: #cee0ea;
  }
  &.assigned {
    background-color: #eae8ce;
  }
  &.inprogress {
    background-color: #ceead1;
  }
}

.fill-width {
  width: 100%;
  max-width: 320px;
}

.contract-vehicle-list-imagecv {
  width: 70px !important;
  display: block;
  margin-top: 40px;
}

.hide {
  display: none;
}

.location-hint {
  color: #00a6ff;
}

.rightmargin {
  margin-right: 10px;
  float: left;
}

.pick-me {
  display: inline-block;
}

img.happydriver {
  height: 60px;
}

.highlight {
  color: #00b8d4;
  font-weight: bold;
}

.about-us {
  h1 {
    color: #444444;
    text-align: center;
  }
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    line-height: 1.5;
    margin: 20px 0;
  }
  .highlight {
    color: #00b8d4;
    font-weight: bold;
  }
  .quote {
    font-style: italic;
    color: #777777;
    margin: 20px 0;
  }
}

.types-of-trips {
  float: left;
  width: 96%;
  padding: 10px 20px;
  border: solid 3px #009688;
  background: #009688;
  color: white !important;
  font-size: 16px;
  margin-bottom: 30px;
  border-radius: 3px;
  span {
    float: right;
    color: rgb(239, 234, 150);
    font-weight: bold;
    letter-spacing: 5px;
    font-size: 20px;
  }
}

.cats-holder {
  display: inline-block;
}

.category-type-sample {
  cursor: pointer;
  background-color: white;
  border: solid 1px #aec4a8;
  padding: 3px 13px;
  margin: 5px 10px 5px 0px;
  text-transform: capitalize;
  font-size: 15px;
  width: fit-content;
  border-radius: 3px;
  float: left;
  &.selected {
    border: solid 1px #82dd6d;
  }
}

.what-is-qiktruck {
  li {
    margin-bottom: 30px;
  }
}

.customer-menu-options {
  cursor: pointer;
  border: 1px solid #aec4a8;
  margin: 5px;
  padding: 10px 10px 20px;
  border-radius: 5px;
  background: #fff;
  width: 320px;
  margin-right: 10px;
  min-height: 150px;
  display: block;
  float: left;
  a {
    color: #4f6367 !important;
    cursor: pointer;
  }
}

.common-box {
  cursor: pointer;
  border: 1px solid #aec4a8;
  margin: 5px;
  padding: 10px 10px 20px;
  border-radius: 5px;
  background: #fff;
  a {
    color: #4f6367;
    cursor: pointer;
  }
}

.developer-api-documentation {
  li {
    margin: 30px 0px;
    padding: 0px 0px;
  }
}

.free-api-tier {
  ul {
    box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
    float: left;
    margin-top: 20px;
    margin-bottom: 40px;
    background-color: white;
    padding: 10px 20px;
    li {
      margin-left: 10px;
    }
  }
  button {
    background-color: #104956;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background-color: #10491f;
    }
  }
}

.developer-prices {
  ul {
    box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
    float: left;
    width: 360px;
    margin-right: 5px;
    padding: 10px 20px;
    background-color: white;
    li {
      margin-left: 10px;
    }
  }

  button {
    background-color: #104956;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background-color: #10491f;
    }
  }
}

.api-images {
  border-radius: 10px;
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  max-width: 70%;
}

.warehouse-apis {
  width: 100%;
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  border-radius: 5px;
}

@media screen and (max-width: 1000px) {
  .api-images {
    border-radius: 10px;
    box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
    max-width: 100%;
  }
  .developer-prices {
    ul {
      width: 100%;
    }
  }
}

.api-request {
  max-width: 500px;
}

.dark-green {
  color: #40c073;
}

.wide-button {
  min-width: 150px;
  margin-left: 100%;
}

.float-lefter {
  float: left;
  position: relative;
  left: -80%;
}
.dark-red {
  color: #ff0000;
  font-size: 16px;
}

.center {
  text-align: center;
  margin: 0 auto;
  position: relative;
}
.b-center {
  text-align: center;
  margin: 0 auto;
  position: relative;
  align-self: center;
  display: block;
}
.center-text {
  text-align: center;
}
.otherOptions {
  align-items: center;
  h6 {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
  }
}
.instant-quotations-parent {
  display: flex;
  justify-content: center;
  .instant-quotations {
    margin: 0 auto;
    margin-top: 20px;
    padding: 10px 30px;
    margin-bottom: 10px;
    cursor: pointer !important;
    &:disabled {
      background-color: #ffeb3b63;
      &:hover {
        background-color: #e3e7e4;
        border-color: #d2d8d3;
      }
    }
  }
}
.chat-quotations-parent {
  justify-content: center;
  .chat-quotations {
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 10px 30px;
    cursor: pointer !important;
    &:disabled {
      background-color: #ffffff63;
      &:hover {
        background-color: #e3e7e4;
        border-color: #d2d8d3;
      }
    }
  }
}
.trucktype-left {
  background-color: #e9ede7;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 2px;
  font-weight: bold;
  display: inline;
  float: left;
  margin-top: 15px;
}

.pricepreview-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-links {
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  width: 100%;
}
.pricepreview {
  float: left;
  width: 30%;
  max-width: 120px;
  padding: 3px 8px;
  border: solid 1px #ccc;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 0 3px 3px 0 #ddd, 0 0 2px 0 #ddd;
  .trucktype {
    font-size: 11px;
    color: #4f6367;
  }
  .price {
    font-size: 12px;
    font-weight: bold;
    color: #4f6367;
    text-align: right;
  }
  .trucktype,
  .image,
  .price {
    float: left;
    align-self: center;
  }
  .trucktype,
  .price {
    width: 100%;
  }
  .business-tip {
    font-size: 10px;
    float: left;
    align-self: center;
    width: 100%;
    &.right {
      text-align: right;
    }
  }
}

.notactive {
  opacity: 0.5;
}

.prices-cta {
  margin: 10px;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  display: block;
}

.prices-truck {
  a {
    color: #000;
  }
}

.hints {
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 50px;
  p {
    font-size: 12px;
  }

  &.ads {
    p {
      padding-left: 10px;
    }
    h2 {
      padding: 5px 10px;
    }
  }
}
.biz {
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;
  margin-bottom: 50px;
  p {
    font-size: 12px;
  }

  &.ads {
    p {
      padding-left: 10px;
    }
    h2 {
      padding: 5px 10px;
    }
    h6 {
      padding: 7px 12px;
    }
  }
}

.trip-info {
  text-align: center;
}

.new {
  background-color: #4caf50;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
}

.padding-top {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #ccc;
  margin-top: 40px;
}

.download-invoice {
  button {
    background-color: #4caf50;
    color: #ffffff;
    padding: 3px 20px;
    border-radius: 3px;
    border: none;
    box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
    cursor: pointer;
    font-size: 16px;
    &:hover {
      background-color: #4caf50;
    }
  }
}

button {
  cursor: pointer !important;
}

.moving-headline {
  min-height: 100px;
}
.banner-vehicles {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.banner-vehicle {
  display: inline-block;
  transform-origin: center;
  animation: ease-in-out infinite, fadeInOut 4s linear infinite;
}

.banner-vehicle img {
  max-width: 100px;
  height: auto;
}

@keyframes dance {
  0%,
  100% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-10px) rotate(-5deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(-10px) rotate(5deg);
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.banner-vehicle:nth-child(1) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(2) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(3) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(4) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(5) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(6) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(7) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(8) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(9) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(10) {
  animation-delay: calc(-2s * random());
}

.banner-vehicle:nth-child(11) {
  animation-delay: calc(-2s * random());
}

@keyframes random {
  0% {
    --random: 0;
  }
  100% {
    --random: 1;
  }
}

/* Add a class to the image element you want to animate */
.logo-dark,
.moving-flag {
  display: inline-block;
  position: relative;
  animation: moveTruck 90s linear infinite;
}

/* Define the moveTruck animation using keyframes */
@keyframes moveTruck {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(50px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}

.biz-reg {
  hr {
    border: solid 3px #bbf4c2;
  }
}

.inline-m {
  font-size: 11px;
  line-height: 14px;
  margin-left: 5px;
  color: #655638;
}

.supplier-rocket {
  position: absolute;
  float: right;
  font-size: 150px;
  right: 90px;
  top: 10px;
  animation: rotateColors 5s linear infinite;
}

@media screen and (max-width: 768px) {
  .supplier-rocket {
    position: relative;
  }
}

@keyframes rotateColors {
  0% {
    color: #ff0000; /* red */
  }
  20% {
    color: #ff7f00; /* orange */
  }
  40% {
    color: #ffff00; /* yellow */
  }
  60% {
    color: #00ff00; /* green */
  }
  80% {
    color: #9999f5; /* blue */
  }
  100% {
    color: #ff00ff; /* violet */
  }
}

.btn-secondary {
  color: #2b2929;
  background-color: #ffffff;
  border-color: #d1d5d9;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #1b2020;
}

.nudge-driver {
  background-color: #dfc416;
  color: #fff;
  padding: 3px 20px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
  cursor: pointer;
  font-size: 14px;
  margin-right: 10px;
  display: inline-flex;
  margin-bottom: 10px;
  &:hover {
    background-color: #23ba1a;
  }
  &.sms {
    background-color: #cbda78;
    color: #000;
  }
  &.call {
    background-color: #c19cd4;
  }
  &.whatsapp {
    background-color: #b6d6a2;
    color: #000;
  }
  &.buzz {
    background-color: #93b4d7;
  }
}

@keyframes ring {
  0%,
  100% {
    transform: rotate(0deg) scale(1);
    filter: hue-rotate(0deg);
  }
  10% {
    transform: rotate(25deg) scale(1.1);
    filter: hue-rotate(90deg);
  }
  20% {
    transform: rotate(-5deg) scale(0.9);
    filter: hue-rotate(180deg);
  }
  30% {
    transform: rotate(5deg) scale(1.1);
    filter: hue-rotate(90deg);
  }
  40% {
    transform: rotate(-5deg) scale(0.9);
    filter: hue-rotate(180deg);
  }
  50% {
    transform: rotate(5deg) scale(1.1);
    filter: hue-rotate(90deg);
  }
  60% {
    transform: rotate(-15deg) scale(0.9);
    filter: hue-rotate(180deg);
  }
  70% {
    transform: rotate(5deg) scale(1.1);
    filter: hue-rotate(90deg);
  }
  80% {
    transform: rotate(-5deg) scale(0.9);
    filter: hue-rotate(180deg);
  }
  90% {
    transform: rotate(5deg) scale(1.1);
    filter: hue-rotate(90deg);
  }
}

.phone {
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: ring 3s infinite;
  font-size: 50px;
  color: #fff;
}

.sad {
  background-color: #fff;
  color: rgb(169, 169, 169);
  border-color: rgb(191, 190, 190);
}
.goods-list {
  margin-top: 10px;
  margin-bottom: 5px;
}
.goods-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .goods-item-name {
    width: 100%;
    margin-right: 10px;
  }
  .goods-item-qty {
    width: 100px;
    margin-right: 10px;
  }
  .goods-item-price {
    width: 100px;
    margin-right: 10px;
  }
  .goods-item-total {
    width: 100px;
    margin-right: 10px;
  }
  .goods-item-remove {
    width: 100px;
    margin-right: 10px;
  }
}
.add-goods-item {
  border-radius: 3px;
  background-color: white;
  border: solid 1px #ced4da;
  margin: 5px;
}
.search-goods-list {
  width: 90%;
  max-width: 360px;
  height: 300px;
  background: white;
  position: absolute;
  z-index: 1000;
  margin-top: 40px;
  float: left;
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px 0px;
  overflow-y: scroll;
  box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
}
.search-goods-list-item {
  cursor: pointer;
  border-bottom: solid 1px #ccc;
  padding: 5px 0px;
  color: #072f1f;
  &:hover {
    background-color: #ccc;
  }
}
.close-search-goods-list {
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 18px;
  color: rgb(133, 103, 103);
  padding: 0px 6px 4px 15px;
  cursor: pointer;
  &:hover {
    color: #ff0000;
  }
}
.search-goods {
  float: left;
  display: inline-flex;
  margin: 0px;
  margin-top: 15px;
  margin-bottom: 70px;
  width: 100%;
  input,
  button {
    border-radius: 3px;
    padding: 3px 5px;
    background-color: white;
    border: solid 1px #ced4da;
  }
  .search-goods-input {
    width: 100%;
    margin-right: 15px;
  }
  .search-goods-button {
    width: 100px;
    margin-right: 10px;
  }
}
.search-goods-icon {
  i {
    font-size: 25px;
    padding: 5px;
  }
}
.dimensions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  .dimensions-item {
    float: left;
    width: 20%;
    margin-right: 3px;
    min-height: 120px;
    label {
      font-size: 10px;
    }
  }
}

.goods-list-start {
  margin-top: 50px;
}
.remove-goods-item {
  color: red;
  float: left;
}
.goods-list-item {
  padding: 5px 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 3px 5px;
  background: white;
  position: relative;
  &:hover {
    .remove-goods-item {
      display: block;
    }
  }
  .remove-goods-item {
    display: none;
    float: right;
  }
}
.goodtype {
  margin-left: 5px;
  font-size: 13px;
  span {
    font-size: 12px;
    color: #ccc;
  }
}
.volume {
  margin-left: 5px;
  margin-right: 5px;
  background: rgb(222, 245, 245);
  font-size: 10px;
  letter-spacing: 0.5px;
}
.quantity {
  margin-left: 15px;
  margin-right: 15px;
}
.kgs {
  margin-left: 15px;
  margin-right: 10px;
  margin-top: 4px;
  float: right;
  font-size: 11px;
  color: #5c605d;
}

.trip-info-price {
  width: 100%;
  float: left;
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

#trucklist {
  justify-content: center;
  align-items: center;
  display: flex;

  .trucklisttype {
    border: 1px solid grey;
    margin: 3px;
    padding: 3px 13px;
    border-radius: 4px;
    background: white;
    img {
      width: 80px;
    }
  }
}

.hint {
  font-size: 10px;
  float: right;
}

.errors-list {
  color: rgb(226, 78, 78);
  font-size: 12px;
  padding-left: 0px;
  list-style: none;
  li {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 5px;
    color: rgb(226, 78, 78);
    text-align: center;
    a {
      color: rgb(226, 78, 78);
    }
  }
}
.mini-menu-vehicles {
  margin-bottom: 10px;
  .mini-menu-vehicle {
    float: left;
    cursor: pointer;
    width: 30%;
    max-width: 130px;
    height: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    margin: 3px;
    border-radius: 5px;
    background-color: #ffff004f;
    box-shadow: 0 4px 5px 0 #ddd, 0 0 4px 0 #ddd;
    &.picked {
      background-color: #84ff00;
      box-shadow: 0 4px 5px 0 #cbe8ad, 0 0 4px 0 #cbe8ad;
    }
  }
  img {
    width: 100%;
    margin-right: 10px;
  }
}

.deliver-with-price {
  input {
    border-radius: 100px !important;
    padding: 1px 15px;
  }
}
.offer {
  border-color: #f6d793;
  border-width: 3px;
  color: #000000;
  padding: 4px 15px;
  border-radius: 10px !important;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
  margin-top: 5px;
  box-shadow: none;
}

.pricepreview-trucktype {
  font-size: 10px;
}
.pricing-container {
  text-align: center;
  .divvyup {
    z-index: 1;
  }
  .pricing-card {
    border: none;
    border-radius: 10px;
    box-shadow: none;
    transition: transform 0.3s;
    &:hover {
      transform: translateY(-10px);
    }

    .pricing-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      color: #4f6367;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        padding: 8px 0;
        border-bottom: 1px solid #eaeaea;

        &:last-child {
          border-bottom: none;
        }
      }
    }

    button {
      margin-top: 20px;
      background-color: #ffdd00;
      border: none;
      border-radius: 5px;
      padding: 10px 20px;
      color: #000000;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: #f5cf16;
      }
    }
  }
}

.biz-price {
  span {
    font-size: 28px;
    font-weight: bold;
    color: #4f6367;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
}

/* ... (Existing styles from earlier) ... */

.landing-container {
  padding: 50px 0;
}

.hero-section {
  margin-bottom: 50px;

  .hero-image {
    max-width: 90%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;
  }
}

.benefits-section {
  margin-bottom: 50px;

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  ul {
    list-style-type: none;
    padding-left: 20px;
    li {
      list-style-type: none;
      padding: 10px 0;
    }
  }
}

.logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .company-logo {
    margin: 0 20px 20px;
    max-height: 60px; // Adjust based on your logo sizes
    filter: grayscale(100%); // Makes logos grayscale, remove if not needed
  }
}

.reviews-slider-container {
  transition: all 1s ease-in-out;
  height: 100%;
  .carousel-indicators {
    li {
      background-color: #888;
      &.active {
        background-color: #333;
      }
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
  }

  .review-content {
    font-size: 20px;
    text-align: center;
    padding: 50px;
    border-radius: 10px;
    font-style: italic;
  }
  .carousel,
  .carousel-inner {
    height: 100%;
    min-height: 360px;
  }
  .carousel-item {
    height: 100%;
  }
  .carousel-caption {
    right: 0;
    left: 0;
    bottom: 10px;
    text-align: center;
    padding: 10px;
    background-color: transparent;
    h5,
    p {
      color: #000000;
    }
  }
}

.biz-email-input {
  width: 100%;
  float: left;
  margin-bottom: 5px;
  border-radius: 3px;
  padding: 3px 5px;
  background-color: white;
  border: solid 1px #ced4da;
}

.biz-email-container {
  width: 100%;
  float: left;
  margin: 5px;
  border-radius: 3px;
  padding: 3px 5px;
  background-color: #f4fbf6;
  &.error {
    background-color: #f8d2d2;
  }
}
.red {
  color: red;
}

.price-truck-image {
  height: 50px;
}
.font-32 {
  font-size: 32px;
}

.action-banner {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-bottom: 50px;
  font-size: 34px;
  display: flex;
}

.inline-button {
  border-radius: 5px;
  border: solid 1px #ccc;
  color: blue;
}

.hints2 {
  color: blue;
}

.vehicle-size {
  padding: 10px 10px;
  margin: 0 auto;
  width: min-content;
  display: inline-block;
}
.vehicle-size-parent {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0px 0px 10px 0px;
  h4 {
    margin-bottom: 10px;
    background-color: #c8dcb9;
    padding: 5px 10px;
  }
}

.vehicle-examples {
  float: left;
  border-top: 1px solid #ccc;
  width: 100%;
  padding: 10px 20px;
  font-size: 14px;
  font-style: italic;
}

.full-width {
  width: 100%;
  display: flex;
}

.chat-message {
  display: flex;
  margin-bottom: 10px;
}

/* Style for message bubble */
.chat-message .bubble-owner {
  padding: 10px 15px;
  border-radius: var(--bubble-radius);
  max-width: 30%;
  word-wrap: break-word;
  color: #7fd87f;
}
.chat-message .bubble-time {
  border-radius: var(--bubble-radius);
  word-wrap: break-word;
  color: #a6bfa6;
}
.chat-message .bubble {
  padding: 10px 15px;
  border-radius:0 var(--bubble-radius);
  max-width: 70%;
  word-wrap: break-word;
}

/* Message from the current user: align right */
.chat-message.user {
  justify-content: flex-end;
}

.chat-message.user .bubble {
  background-color: var(--user-msg-bg);
  color: var(--user-text-color);
}

/* Message from others: align left */
.chat-message.other {
  justify-content: flex-start;
}

.chat-message.other .bubble {
  background-color: var(--other-msg-bg);
  color: var(--other-text-color);
}

.qik-truck-container {
  align-self: center;
  display: inline-flex;
  overflow: hidden;
  flex: 1;
  padding-left: 20%; /* Ensures letters that slide in from the left stay hidden until fully in place */
}

/* The base class that each letter uses */
.slide-in-left {
  opacity: 0;
  animation-name: slideInLeft;
  animation-duration: 9s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite; /* This makes it repeat forever */
}

/* Keyframes for sliding in from left, pausing, then exiting to the right */
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  20% {
    transform: translateX(0);
    opacity: 1;
  }
  60% {
    transform: translateX(0);
    opacity: 1;
  }
  80% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.app-download-links {
  display: block;
  justify-content: center;
  img {
    margin-right: 20px;
    margin-top: 20px;
    width: 150px !important;
  }
}


